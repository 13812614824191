import { createStore } from 'vuex';
import CurrencyRates from '@/CurrencyRates.js';
import LanguageSetting from '@/LanguageSetting.js';

export default createStore({
  state: {
    selectedCurrency: getDefaultCurrency(),
    rate: CurrencyRates[getDefaultCurrency()].rate,
    rateCountry: CurrencyRates[getDefaultCurrency()].rateCountry,
    selectedLanguage: getDefaultLanguage(),
    lastVisit: "",

    shopName: process.env.VUE_APP_SHOP_NAME || 'NA',
    shopID: process.env.VUE_APP_SHOP_ID || 'NA',
  },
  mutations: {
    changeCurrency(state, myCurrency) {
      state.selectedCurrency = myCurrency;
      state.rate = CurrencyRates[myCurrency].rate;
      state.rateCountry = CurrencyRates[myCurrency].rateCountry;
    },

    changeLanguage(state, newLanguage) {
      state.selectedLanguage = newLanguage;
    }
  },
  
  getters: {
    translate: (state) => (key) => {
      return state.selectedLanguage === 'CN' ? key : (LanguageSetting[state.selectedLanguage][key] || key);
    }
  }
});

function getDefaultCurrency() {
  const storedData = JSON.parse(localStorage.getItem("myData"));
  const defaultCurrency = process.env.VUE_APP_DEFAULT_CURRENCY;
  if (storedData && storedData.otherData && storedData.otherData.selectedCurrency) {
    return storedData.otherData.selectedCurrency;
  } else {
    return defaultCurrency; // 默认货币
  }
}

function getDefaultLanguage() {
  const storedData = JSON.parse(localStorage.getItem("myData"));
  const defaultLanguage = process.env.VUE_APP_DEFAULT_LANGUAGE;
  if (storedData && storedData.otherData && storedData.otherData.selectedLanguage) {
    return storedData.otherData.selectedLanguage;
  } else {
    return defaultLanguage; // 默认語言
  }
}