//export const apiDomain = "http://localhost:5000";
//export const stripeDomain = "http://localhost:4242";
//export const token = "1ec949a15fb709370f";

//export const apiDomain = "https://shop1.wisoodigital.com";
//export const stripeDomain = "https://shop1.wisoodigital.com";

//export const apiDomain = process.env.VUE_APP_DOTNET_API_URL;
//export const stripeDomain = process.env.VUE_APP_STRIPE_API_URL;
export const shopName = process.env.VUE_APP_SHOP_NAME;
export const shopID = process.env.VUE_APP_SHOP_ID;