// 在 src/router/index.js 中更新路由

import { createRouter, createWebHistory } from "vue-router";
import { shopName } from "@/config";

const routes = [
  {
    path: "/test-page",
    name: "TestPage",
    component: () => import("@/views/TestPage.vue"),
  },
  {
    path: "/:typeName?",
    name: "HomePage",
    component: () => import(`@/views/${process.env.VUE_APP_HOMEPAGE_LAYOUT}.vue`),
    //meta: { requiresAuth: true },
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: () => import("@/views/AboutUsPage.vue"),
  },
  {
    path: "/category-list",
    name: "CategoryList",
    component: () => import("@/views/CategoryListPage.vue"),
  },
  {
    path: "/product-list/:typeName",
    name: "ProductList",
    component: () => import("@/views/ProductListPage.vue"),
    props: true,
  },
  {
    path: "/product-detail/:pURL",
    name: "ProductDetail",
    component: () => import("@/views/ProductDetailPage.vue"),
    props: true,
  },
  {
    path: "/content-detail/:pageID",
    name: "ContentDetail",
    component: () => import("@/views/ContentDetailPage.vue"),
    props: true,
  },
  {
    path: "/promotion-event/:eventURL",
    name: "PromotionEvent",
    component: () => import("@/views/PromotionEvent.vue"),
    props: true,
  },
  {
    path: "/cart-page",
    name: "Cart",
    component: () => import("@/views/CartPage.vue"),
    props: true,
  },
  {
    path: "/checkout-page",
    name: "Checkout",
    component: () => import("@/views/CheckoutPage.vue"),
    props: true,
  },
  {
    path: "/checkout-page-MPayPersonal",
    name: "CheckoutMPayPersonal",
    component: () => import("@/views/CheckoutPageMPayPersonal.vue"),
    props: true,
  },
  {
    path: "/checkout-page-BOCPersonal",
    name: "CheckoutBOCPersonal",
    component: () => import("@/views/CheckoutPageBOCPersonal.vue"),
    props: true,
  },
  {
    path: "/checkout-page-FPSPersonal",
    name: "CheckoutFPSPersonal",
    component: () => import("@/views/CheckoutPageFPSPersonal.vue"),
    props: true,
  },
  {
    path: "/pay-success",
    name: "PaySuccess",
    component: () => import("@/views/PaySuccessPage.vue"),
    props: true,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("@/views/PrivacyPolicyPage.vue"),
    props: true,
  },
  {
    path: "/sales-refunds",
    name: "SalesAndRefunds",
    component: () => import("@/views/SalesRefundsPage.vue"),
    props: true,
  },

  {
    path: "/shipping-pickup",
    name: "ShippingPickup",
    component: () => import("@/views/ShippingPage.vue"),
    props: true,
  },

  {
    path: "/pay-error/:errorMessage?",
    name: "PayError",
    component: () => import("@/views/PayErrorPage.vue"),
    props: true,
  },

  //CMS ROUTER
  {
    path: "/admin/goodstype",
    name: "CMSGoodsType",
    component: () => import("@/views/CMS/CMS_GoodsType.vue"),
    props: true,
    meta: { requiresAuth: true },
  },

  {
    path: "/admin/goods",
    name: "CMSGoods",
    component: () => import("@/views/CMS/CMS_Goods.vue"),
    props: true,
    meta: { requiresAuth: true },
  },

  {
    path: "/admin/login-page",
    name: "LoginPage",
    component: () => import("@/views/CMS/CMS_login.vue"),
    props: true,
  },

  {
    path: "/admin/cms-content-pages",
    name: "CMSContentPages",
    component: () => import("@/views/CMS/CMS_ContentPages.vue"),
    props: true,
    meta: { requiresAuth: true },
  },

  {
    path: "/admin/promotion-event",
    name: "CMSPromotionEventPages",
    component: () => import("@/views/CMS/CMS_PromotionEvent.vue"),
    props: true,
    meta: { requiresAuth: true },
  },

  {
    path: "/admin/media-page",
    name: "MediaPage",
    component: () => import("@/views/CMS/CMS_MediaLib.vue"),
    props: true,
    meta: { requiresAuth: true },
  },

  {
    path: "/admin/order-items-page",
    name: "CMSOrderItems",
    component: () => import("@/views/CMS/CMS_OrderItems.vue"),
    props: true,
    meta: { requiresAuth: true },
  },
];

import { checkToken } from "@/api/CMSApi.js";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const token = JSON.parse(localStorage.getItem("userToken"));
    if (!token) {
      next({ path: "/admin/login-page" });
    } else {
      checkToken(token)
        .then((result) => {
          if (result === 1) {
            next(); // 继续当前的导航
          } else {
            next({ path: "/admin/login-page" }); // 无效 token，重定向到登录页面
          }
        })
        .catch((error) => {
          // 发生错误时重定向到登录页面
          console.error("Error while checking token:", error);
          next({ path: "/admin/login-page" });
        });
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || `${shopName}`;
  next();
});

router.afterEach((to) => {
  console.log("Google Analytics");
  if (typeof gtag !== 'undefined') {
    window.gtag('config', process.env.VUE_APP_GA4, {
      page_path: to.fullPath,
    });
  }
});


export default router;
