const LanguageSetting = {
    EN: {
      //Home page
      "最新熱選": "Latest Arrival",
      "首頁": "Home",
      "付款": "CHECK",
      "貨品及服務查詢": "Goods and Services Inquiry",

      //Product Page
      "信用卡 付款": "Credit Card - Check Out",
      "MPay 付款": "Mpay - Check Out",
      "參加 - 放入購物籃" : "Join & Add to Cart",
      "放入購物籃" : "Add to Cart",
      "貨存" : "Inventory",
      "數量" : "Amount",
      "付加費用" : "Add Value", 

      //Cart Page
      "你參加的活動" : "The events you are participating in",
      "你選購的產品" : "The products your have chosen",
      "付款人資料" : "Buyer Information",
      "姓名" : "Your Name",
      "備註(如有特別要求)" : "Remarks (for any special requests)",
      "備註" : "Remarks",
      "區碼" : "Country Code",
      "電話(貨運聯絡用)" : "Phone (for shipping contact)",
      "電話" : "Phone",
      "Email (使用 Credit Card 才須填寫)" : "Email (Required only if using a Credit Card)",
      "Email" : "Email",
      "國家/地區" : "Country/Region",
      "送貨地址 及 城市" : "Delivery Address",
      "郵遞區號 (選填)" : "PO Box (Optional)",
      "地址" : "Address",
      "掃描以下二維碼支付": "Scan QR Code for payment",
      "上載截圖": "Upload PAID ",
      "💪📦 全單己滿 $":"💪📦 Pickup or Delivery - Free delivery for orders over $",
      "可享免運費":" ",
      "🙏 訂購總額未滿 $":"🙏 For orders under $",
      "，須加送貨費用 +$":", a delivery fee will be added: +$",
      "本地送貨":"Home Delivery",
      "本地送貨(免費)":"Home Free Delivery",
      "自取":"Self-Pickup",
      "選擇送貨時間 (10AM-07PM)":"Choose Delivery Time (10AM-7PM)",
      "送貨費用" : "Delivery Fee",
      "請輸入「買家姓名」<br>" : "Please enter Buyer's Name <br>",
      "請輸入「電話號碼」<br>" : "Please enter Phone Number <br>",
      "請選擇「送貨方式」<br>" : "Please select delivery Method <br>",
      "請選擇「送貨時間」<br>" : "Please select Delivery Time <br>",
      "請輸入「送貨地址」<br>" : "Please enter Delivery Address <br>",
      "總數" : "TOTAL",
      "免運費" : "Free delivery",
      "運費" : "Delivery Fee",
      "滿" : "Order over",
      "繼續選購" : "Continue Shopping",
      "送貨方式" : "Delivery Method",
      "門店地址" : "Shop Address",
      "全單己滿" : "Order over $",
      "請用「過數易」或 MPay 存入戶口" : "Please deposit into account using EasyTransfer or MPay",
      "選擇代收店 / 櫃" : "Please select the eBuy shop or locker location",
      "eBuy (只限澳門)" : "eBuy (For Macau Only)",
      "海外速遞(包括香港)" : "Oversea shipping (include Hong Kong)",
      "我們提供全球送貨，包括以下國家地區：" : "We offer worldwide shipping, including the following countries and regions:",
      "中國大陸、香港、澳門和台灣地區、新加坡、馬來西亞、泰國、越南、印尼、日本、韓國、美國、加拿大、英國、德國、法國、澳大利亞和新西蘭。" : "Mainland China, Hong Kong, Macau, Taiwan, Singapore, Malaysia, Thailand, Vietnam, Indonesia, Japan, South Korea, the United States, Canada, the United Kingdom, Germany, France, Australia, and New Zealand.",
      "澳門點選 eBuy 運費全免" : "Free Shipping in Macau China by eBuy",

      //Check out page
      "支付資料" : "Payment Information",
      "安全支付" : "Security Payment",
      "友善提示" : "Friendly Reminder",
      "正在建立安全支付通道" : "Establishing a Secure Payment Connection",
      "完成支付" : "Confirm",
      "請使用 MPay 掃描二維碼": "Please use MPay to scan the QR code.",
      "付款後截圖並上載，完成下單": "After payment, take a screenshot and upload it to complete the order.",
      "請上傳 JPG, PNG 圖檔":"Please upload JPG, PNG format",
      "「過數易」或「MPay」支付":"EasyTransfer or MPay",

      //Success Page
      "你己成功選購的產品" : "The products you have successfully purchased",
      "你己成功參加的活動" : "The events you have successfully joined",
      "確認信件已發送至" : "A confirmation email has been sent to",
      "請記録你的訂購號碼" : "Please record your order number",
      "訂購號碼" : "Order Number",
      "如有查詢，請聯絡客服熱線" : "For any inquiries, please contact our customer service hotline.",   
      "常見問題" : "Frequently Questions",   
      "系統出現問題(連線超時), 請致電我們跟進你的訂單" : "There is a problem with the system (connection overtime). Please call us to follow up on your order.", 
      "系統出現問題(確認支付失敗), 請致電我們跟進你的訂單" : "There is a problem with the system (payment confirmation failure). Please call us to follow up on your order.", 
      "正在確認支付狀態..." : "Currently verifying payment status…", 
      "下單成功，但後台系統通知出現問題，請電話聯絡我們跟進你的訂單 " : "Order placed successfully, but there was an issue with the system notification. Please contact us by phone to follow up on your order.", 
      "最後支付確認出現問題, 請致電我們跟進你的訂單, 訂單號： " : "Please contact us to follow up on your order, Order Number: ", 
      "支付成功！感谢您的購買。" : "Payment successful! Thank you for your purchase.", 

      //Share for shops
      "澳門南灣大馬路 387 號，華南大廈 3A" : "Avenida da Praia Grande, FL. 2A, Edf. Wa Nam, Macau",  

      //Dedicated for Shops

      //Seafood853.com
      "優鮮 853 - 全部貨品" : "Seafood 853 - All Products",    

      //JapanPearl.co
      "今季新品" : "New Arrivals for This Season",  
      "專營日本 Akoya 真珠及首飾" : "Specializing in Japanese-Made Jewellery & Akoya Pearl", 
      "歡迎 Whatsapp 預約地點 一對一試戴選購" : "Feel free to WhatsApp us to book a one-on-one fitting and purchase at a designated location.",  

      //RebornJewellery.co
      "專營晚裝豪華首飾" : "Specializing in Luxurious Evening Accessories",  

    },
  };
  
  export default LanguageSetting;
  